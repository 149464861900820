import React, { Component } from 'react';
import './ProductPromotion.css';


class ProductBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productInfo: undefined
        }
    }

    componentWillMount() {
        this.setState({
            productInfo: this.props.productInfo
        });
    }


    render() {
        if ( this.state.productInfo !== undefined ) {
            //Verificamos que esté establecida l a url de google play / apple story y sitio web
            //si no está establecida, se imprime un span vacío
            let googlePlay, iOs, website;
            if(this.state.productInfo.acf.url_from_google_play === ""){
                googlePlay = <span/>
            } else {
                googlePlay = <a href={this.state.productInfo.acf.url_from_google_play} rel="noopener noreferrer"  target="_blank">
                                <img className="ProductPromotion_storeIcon" src="../res/icons/store_Google.png" alt="Proudly made by SevenSoft"/>
                            </a>;
            }
            if(this.state.productInfo.acf.url_web === ""){
                website = <span/>
            } else {
                website = <a href={this.state.productInfo.acf.url_web} rel="noopener noreferrer"  target="_blank">
                                <img className="ProductPromotion_storeIcon" src="../res/icons/store_website.svg" alt="Proudly made by SevenSoft"/>
                            </a>;
            }
            if(this.state.productInfo.acf.url_from_apple_store === ""){
                iOs = <span/>
            } else {
                iOs = <a href={this.state.productInfo.acf.url_from_apple_store} rel="noopener noreferrer"  target="_blank">
                                <img className="ProductPromotion_storeIcon" src="../res/icons/store_iOs.svg" alt="Proudly made by SevenSoft"/>
                            </a>;
            }
            return (
                <div className="ProductPromotion">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 offset-md-1">
                                <div className="ProductPromotion_card square">
                                    <div className="square_content">
                                        <h4>Try it yourself!</h4>
                                        {googlePlay}
                                        {iOs}
                                        {website}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 ProductPromotion_description">
                                <h3>{this.state.productInfo.acf.product_promotion_title}</h3>
                                <h6>{this.state.productInfo.acf.product_promotion_description}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <p className="text-center">Cargando ...</p>
        }
    }

}

export default ProductBanner;
