import React, { Component } from 'react';
import {Link} from "react-router-dom";
import './TopMenu.css';
import { Collapse, Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';

// variable utilizada para controlar el estado del menu: abierto o cerrado:
var isOpen=false;
//varaible utilizada para desactivar el scroll, son las teclas que se pueden utilizar para hacer scroll
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

/*-----------------------------------------------------------------------------
    Nombre:selectItemByID.
    Descripción: Método creado para seleccionar un item del array, por su id,
    luego se crea en el state del componente actual
    Fecha de creación: 5/12/2018
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function selectItemByID(component, itemList, itemID) {
    for (let i = 0; i < itemList.length; i++) {
        // eslint-disable-next-line
        if(itemID==itemList[i].id){
            component.state={menusInfo : itemList[i]};
        }
    }
}
/*-----------------------------------------------------------------------------
    Nombre:preventDefault.
    Descripción: Método creado para desactivar el scroll del sitio, se utiliza cuando se muestra el menu abierto
    Fecha de creación: 15/01/2019
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault)
        e.preventDefault();
    e.returnValue = false;
}
/*-----------------------------------------------------------------------------
    Nombre:preventDefaultForScrollKeys.
    Descripción: Método creado para desactivar el scroll del sitio, se utiliza cuando se muestra el menu abierto
    Fecha de creación: 15/01/2019
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}
/*-----------------------------------------------------------------------------
    Nombre:disableScroll.
    Descripción: Método creado para desactivar el scroll del sitio, se utiliza cuando se muestra el menu abierto
    Fecha de creación: 15/01/2019
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function disableScroll() {
    if (window.addEventListener) // older FF
        window.addEventListener('DOMMouseScroll', preventDefault, false);
    window.onwheel = preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    window.ontouchmove  = preventDefault; // mobile
    document.onkeydown  = preventDefaultForScrollKeys;

    document.body.style.overflow = 'hidden';
}
/*-----------------------------------------------------------------------------
    Nombre:enableScroll.
    Descripción: Método creado para activar el scroll del sitio, se utiliza cuando se muestra el menu abierto
    Fecha de creación: 15/01/2019
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function enableScroll() {
    if (window.removeEventListener)
        window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;

    document.body.style.overflow = 'visible';
}

class TopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconToggler: 'res/icons/menuIcon.svg',
            collapse: true,
            menusInfo: undefined,
            menuBG: '#cacaca'
        };
        this.toggleCustomNavbar = this.toggleCustomNavbar.bind(this);
    }

    toggleCustomNavbar() {
         isOpen = !isOpen;
        this.setState({
            collapsed: !this.state.collapse
        });
        if (isOpen) {
            this.setState({
                menuBG: 'rgba(0,0,0,0.79)'
            });
            disableScroll();
        } else {
            this.setState({
                menuBG: '#000'
            });
            enableScroll();
        }
    }

    componentWillMount(){
        this.setState({
            menuBG: '#000'
        });
    }

    render() {
        var bgColor = this.state.menuBG;
        selectItemByID(this, this.props.menusInfo, this.props.menuID);
        if(this.state.menusInfo !== undefined){
            let menuIcon, menuComplement;
            if( isOpen ){
                menuIcon = <img onClick={this.toggleCustomNavbar} className="mr-2 customToggler menu_clickable" src='../res/icons/menuIconSeven.svg' alt="Menu icon"/>;
                menuComplement = <p className="menu_quote d-none d-md-block d-lg-block .d-xl-block">The best way to get it done faster <span>is to start sooner</span></p>

            } else {
                menuIcon = <img onClick={this.toggleCustomNavbar} className="mr-2 customToggler menu_clickable" src='../res/icons/menuIcon.svg' alt="Menu icon"/>;
                menuComplement = <img className='menu_clickable' src={this.state.menusInfo.acf.logo_icon.url} alt="HomePage"/>;

            }
            return (
                <div className="TopMenu" style={{backgroundColor: bgColor}}>
                    <Navbar color="black" dark>
                        <div className="col-md-1 d-none d-md-block d-lg-block .d-xl-block"/>
                        <NavbarBrand href="/" className="mr-auto" alt="Homepage">
                            {menuComplement}
                        </NavbarBrand>
                        {menuIcon}
                        <div className="col-md-1"/>
                        <Collapse isOpen={isOpen} navbar>
                            <Nav navbar className="offset-md-10 col-md-1">
                                <Link className="menu_button" onClick={this.toggleCustomNavbar} to="/">
                                    <NavItem>Home</NavItem>
                                </Link>
                                <Link className="menu_button" onClick={this.toggleCustomNavbar} to="/services">
                                    <NavItem>Services</NavItem>
                                </Link>
                                <Link className="menu_button" onClick={this.toggleCustomNavbar} to="/about">
                                    <NavItem>About</NavItem>
                                </Link>
                                <Link className="menu_button" onClick={this.toggleCustomNavbar} to="/contact">
                                    <NavItem>Contact</NavItem>
                                </Link>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>
            );
        } else {
            return (
                <div className="TopMenu">
                    <Navbar color="black" dark>
                        <NavbarBrand href="/" className="mr-auto">
                            <br/>
                        </NavbarBrand>
                        <Collapse isOpen={isOpen} navbar>
                            <Nav navbar className="offset-md-10 col-md-1">
                                <NavItem className="menu_button">
                                    <Link onClick={this.toggleCustomNavbar} to="/">Home</Link>
                                </NavItem>
                                <NavItem className="menu_button">
                                    <Link onClick={this.toggleCustomNavbar} to="/services">Services</Link>
                                </NavItem>
                                <NavItem className="menu_button">
                                    <Link onClick={this.toggleCustomNavbar} to="/about">About</Link>
                                </NavItem>
                                <NavItem className="menu_button">
                                    <Link onClick={this.toggleCustomNavbar} to="/contact">Contact</Link>
                                </NavItem>
                                <NavItem className="menu_button">
                                    <Link onClick={this.toggleCustomNavbar} to="/work">Work</Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>
            );
        }

    }

}

export default TopMenu;
