import React, { Component } from 'react';
import Slider from "react-slick";
import HomeHeader from "../HomeHeader/HomeHeader";
import './Home.css';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import SolutionsSection from "../SolutionsSection/SolutionsSection";
import Quote from '../Quote/Quote';
import LoadingScreen from "react-loading-screen";
import ProductOnHomeOne from "../ProductOnHome_one/ProductOnHome_one";
import ProductOnHomeTwo from "../ProductOnHome_two/ProductOnHome_two";
import ProductOnHomeThree from "../ProductOnHome_three/ProductOnHome_three";

/*-----------------------------------------------------------------------------
     Nombre:loadInfo.
     Descripción: Metodo para obtener los datos de wordpress hasta que se carguen.
     Fecha de creación: 4/12/2018
     Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function loadInfo(component) {
    if(component.props.siteData.name === undefined) {
        document.title = "Making the magic ..." ;
        setTimeout(function(){
            loadInfo(component);
        }, 30);
    } else {
        document.title = component.props.siteData.name + " - It's possible";
    }
}

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quotes: [],
            products: [],
            loading: true
        }
        this.contentLoaded = this.contentLoaded.bind(this)
    }

    componentWillMount(){
        fetch(this.props.server+this.props.wpLocation+'wp-json/wp/v2/testimonials/')
            .then((response) => {
                return response.json()
            })
            .then((quotes) => {
                this.setState({ quotes: quotes });
            });
        fetch(this.props.server+this.props.wpLocation+'wp-json/wp/v2/products/')
            .then((response) => {
                return response.json()
            })
            .then((products) => {
                this.setState({ products: products });
            });
    }

    componentDidMount() {
        loadInfo(this);
    }

    contentLoaded () {
        this.setState({
            loading: false
        });
    }

    render() {
        //Configuramos el slider de los tertimonios
        const sliderSettings = {
            dots: true,
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };
        if ( this.state.quotes[0] !== undefined && this.state.products[0] !== undefined) {
            //Variable que cambia entre un testimonio y un slider de testimonios
            let testimonios = <span/>;
            let component =  this;
            if(this.state.quotes.length > 1) {
                testimonios=  <div className='slider-container'>
                    <Slider className={'col-md-12 pd-0'} {...sliderSettings}>
                        {this.state.quotes.map(function (item, i) {
                            return <div key={i}>
                                        <Quote className="quote-bg" quote={component.state.quotes} quoteIndex={i+1}/>
                                    </div>;
                        })}
                    </Slider>
                </div>;
            } else {
                testimonios=
                    <Quote className="quote-bg" quote={this.state.quotes} quoteIndex={1}/>;
            }
            return (
                <div className="Home">
                    <HomeHeader loadingControl={this.contentLoaded} pageID={17} server={this.props.server} wpLocation={this.props.wpLocation}/>
                    <SolutionsSection pageID={17} server={this.props.server} wpLocation={this.props.wpLocation} />
                    <div className={'col-md-12'}>
                        <div className="row">
                            <h4 className='col-md-12 text-center'>An example of our work</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-4 offset-md-1">
                                <ProductOnHomeOne productsList={this.state.products}  server={this.props.server} wpLocation={this.props.wpLocation} />
                            </div>
                            <div className="col-md-6">
                                <ProductOnHomeTwo productsList={this.state.products} server={this.props.server} wpLocation={this.props.wpLocation} />
                                <ProductOnHomeThree productsList={this.state.products} server={this.props.server} wpLocation={this.props.wpLocation} />
                            </div>
                        </div>
                    </div>
                    {testimonios}

                </div>
            );
        } else {
            return (
                <LoadingScreen
                    loading={true}
                    bgColor='#000'
                    spinnerColor='#009FE3'
                    textColor='#fff'
                    logoSrc='res/icons/SevenSoftIcon.svg'
                    text='Loading'>
                    <p>Content done</p>
                </LoadingScreen>
            );
        }
    }

}

export default Home;
