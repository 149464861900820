import React, { Component } from 'react';
import './HomeHeader.css';
import YoutubeBackground from 'react-youtube-background'

class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageInfo: undefined,
            clients: undefined
        };
        this.openModal = this.openModal.bind(this)
    }

    openModal () {
        this.setState({isOpen: true})
    }

    componentWillMount() {
        let url = this.props.server+this.props.wpLocation+"wp-json/wp/v2/pages/"+this.props.pageID;
        fetch(url)
            .then((response) => {
                return response.json()
            })
            .then((pageInfo) => {
                this.setState({ pageInfo: pageInfo });
            });
        let urlClients = this.props.server+this.props.wpLocation+"wp-json/wp/v2/clients/";
        fetch(urlClients)
            .then((response) => {
                return response.json()
            })
            .then((clients) => {
                this.setState({ clients: clients });
            });
    }

    render() {
        if ( this.state.pageInfo !== undefined && this.state.clients !== undefined ) {
            console.log(this.state.clients)
            return (
                <div>
                    <YoutubeBackground
                        videoId={this.state.pageInfo.acf.bg_video_id}     // default -> "jssO8-5qmag"
                        aspectRatio={"16:9"} // default -> "16:9"
                        overlay={"rgba(0,0,0,.7)"}       // defaults -> null | e.g. "rgba(0,0,0,.4)"
                        className={"bg_video_container"}   // defaults -> null
                        onReady={null}       // defaults -> null
                    ><br/>
                    </YoutubeBackground>
                    <div /*onLoad={this.props.loadingControl}*/ className="HomeHeader" style = {{ backgroundImage: 'url(' + this.state.pageInfo.acf.bg_image.url + ')'}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 offset-md-1">
                                    <h2>{this.state.pageInfo.acf.title_line_1}</h2>
                                    <h1>{this.state.pageInfo.acf.title_line_2}</h1>
                                    <div className="HomeHeaderLine" style={{backgroundColor: this.state.pageInfo.acf.base_color}}/>
                                    <h3>{this.state.pageInfo.acf.subtitle} <span>&</span> more</h3>
                                </div>
                                <div className="HomeHeader_button_container col-lg-4 offset-lg-3 col-md-5 offset-md-2 ">
                                    <a className="HomeHeader_button" href="/services" alt="Go to services page">Let's go</a>
                                </div>
                                <div className="HomeHeader_clientList col-md-12">
                                    <div className="row">
                                        {/*Elemento comentado, descomentar cuando el chat este listo*/}
                                        <div className="col-md-11">
                                        {/*<div className="col-md-12">*/}
                                            <h5 className="text-right">These are our clients. Wanna Join?</h5>
                                            <div className="d-flex flex-row-reverse">
                                                {this.state.clients.map((item, i) =>
                                                    <div className="p-2" key={i}>
                                                        <a href={item.acf.website} alt={item.title.rendered} rel="noopener noreferrer" target="_blank">
                                                            <img className="HomeHeader_clientList_img" src={item.better_featured_image.source_url} alt={item.better_featured_image.alt}/>
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/*Elemento comentado, descomentar cuando el chat este listo*/}
                                        <div className="col-md-1">
                                            <h5 className="HomeHeader_pink text-left">Let's talk</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <div className="HomeHeader_Loading"/>
        }
    }

}

export default HomeHeader;
