import React, { Component } from 'react';
import './ProductOnHome_two.css';

class ProductOnHome_two extends Component {

    render() {

        if (this.props.productsList[1] !== undefined) {
            var item = undefined;
            for (let i = 0; i < this.props.productsList.length; i++) {
                if ( this.props.productsList[i].acf.position === '2' ) {
                    item = this.props.productsList[i];
                }
            }

            if ( item !== undefined ) {
                return (
                    <a className="container-fluid" href={"/work/"+item.acf.slug }>
                        <div className="ProductOnHome_two" style = {{ backgroundImage: 'url(' + item.acf.product_background_image.url + ')'}}>
                            <div className="row">
                                <div className="col-md-4 offset-md-8">
                                    <br/>
                                    <h5 className='text-center'>A solution made for</h5>
                                    <img className="ProductOnHome_two_clientLogo" src={ item.acf.product_logo.url } alt={"Image - "+item.acf.product_descriptive_image.alt}/>
                                </div>
                                <div className="col-md-6 offset-md-6">
                                    <br/>
                                    <br/>
                                    <h5 className="col-md-12 text-right">{ item.acf.product_subtitle }</h5>
                                    <h6 className="col-md-12 text-right">{ item.acf.product_description_paragraph_title }</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                )
            } else {
                return (
                    <span/>
                )
            }



        } else {
            return (
                <span/>
            )
        }
    }
}

export default ProductOnHome_two;
