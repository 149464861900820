import React, { Component } from 'react';
import './ProductDescription.css';

/*-----------------------------------------------------------------------------
    Nombre: Array.prototype.compareWith
    Descripción: Método creado para buscar elementos en dos arreglos, los compara
                retorna los datos que coinciden en un arreglo
    Fecha de creación: 12/12/2018
    Autor: Josué Somarribas.
-------------------------------------------------------------------------------*/
// Array.prototype.compareWith = function(arr2) {
//     var coincidence = [];
//     this.sort();
//     arr2.sort();
//     for(var i = 0; i < this.length; i += 1) {
//        // if(arr2.indexOf(this[i]).id > -1){
//         console.log(arr2[i]ID)
//             coincidence.push(this[i]);
//        // }
//     }
//     return coincidence;
// };

class ProductDescription extends Component {

    constructor(props){
        super(props);
        this.state={
            tecnologies: undefined
        };
    }

    componentWillMount(){
        fetch(this.props.server+this.props.wpLocation+'wp-json/wp/v2/technologies/')
            .then((response) => {
                return response.json()
            })
            .then((tecnologies) => {
                this.setState({ tecnologies: tecnologies });
            });

    }

    render() {
        if(this.state.tecnologies !== undefined) {
            // Comparamos los dos arreglos y obtenemos las coincidencias.
            // Estas son las tecnologias utilizadas en este producto
            let technologiesUsed = [];
            let technologiesOnProps = this.props.item.acf.technologies_used_on_this_product;
            let technologiesOnState = this.state.tecnologies;

            for(var i = 0; i < technologiesOnProps.length; i += 1) {
                for(var b = 0; b < technologiesOnState.length; b += 1) {
                    if (technologiesOnProps[i].ID === technologiesOnState[b].id) {
                        console.log(technologiesOnProps[i].ID +"==?"+technologiesOnState[b].id)
                        technologiesUsed.push(technologiesOnState[b]);
                    }
                }
            }







            return (
                <div className="container-fluid">
                    <div className="ProductDescription row">
                        <div className="ProductDescription_verticalCentered col-md-6 productContainer_left">
                            <div className="col-md-8 offset-md-2">
                                <h2>{this.props.item.acf.product_description_paragraph_title}</h2>
                                <p className="ProductDescription_paragraph">{this.props.item.acf.product_description_paragraph}</p>
                            </div>
                        </div>
                        <div className="ProductDescription_verticalCentered col-md-5 productContainer_right">
                            <div className="col-md-12">
                                <h1 className="s">{this.props.item.acf.product_characteristic_one}</h1>
                                <h3 className="s">{this.props.item.acf.product_characteristic_two}</h3>

                                {/*<div className="ProductDescription_line" style={{backgroundColor: this.props.item.acf.base_color}}/>*/}
                                <div className="ProductDescription_line"/>
                                <p className="ProductDescription_HighLights">{this.props.item.acf.product_characteristics_list}</p>
                                {technologiesUsed.map((item, i) =>
                                    <div className="ProductDescription_icon" key={i}>
                                        <img src={item.acf.icon.url} alt={item.acf.icon.alt}/>
                                        <br/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="ProductDescription"/>
            )
        }

    }

}

export default ProductDescription;
