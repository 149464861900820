import React, { Component } from 'react';
import './VideoHeader.css';
/*Youtube popup component */
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

class VideoHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageInfo: undefined,
            videoIsOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal () {
        this.setState({isOpen: true})
    }

    componentWillMount() {
        let url = this.props.server+this.props.wpLocation+"wp-json/wp/v2/pages/"+this.props.pageID;
        fetch(url)
            .then((response) => {
                return response.json()
            })
            .then((pageInfo) => {
                this.setState({ pageInfo: pageInfo });
            });
    }

    render() {
        if ( this.state.pageInfo !== undefined ) {
            return (
                <div className="VideoHeader" style = {{ backgroundImage: 'url(' + this.state.pageInfo.acf.bg_image.url + ')'}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 offset-md-1">
                                <h1 className="VideoHeader_title_line1">{this.state.pageInfo.acf.title_line_1}</h1>
                                <h2 className="VideoHeader_title_line2">{this.state.pageInfo.acf.title_line_2}</h2>
                                <div className="VideoHeaderLine" style={{backgroundColor: this.state.pageInfo.acf.base_color}}/>
                                <h3 className="VideoHeader_subtitle">{this.state.pageInfo.acf.subtitle}</h3>

                            </div>
                            {/*   !! - Video comentado  temporalmente, si se descomenta, lee los datos de wordpress y despliega el video en el componente*/}
                            {/*<div className="video_container col-md-5 offset-md-1 text-right">*/}
                                {/*<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.pageInfo.acf.youtube_id} onClose={() => this.setState({isOpen: false})} />*/}
                                {/*<img onClick={this.openModal} className="video_image img-fluid" src={this.state.pageInfo.acf.video_img.url} alt={this.state.pageInfo.acf.video_img.alt_text}/>*/}
                                {/*<img onClick={this.openModal} className="video_icon img-fluid" src={this.state.pageInfo.acf.video_play_btn.url} alt={this.state.pageInfo.acf.video_play_btn.alt_text}/>*/}
                                {/*<div className="video_bg_card"/>*/}
                            {/*</div>*/}
                        </div>
                        <div className="row">
                            <img className="VideoHeader_icon " src={this.state.pageInfo.acf.header_icon.url} alt={this.state.pageInfo.acf.header_icon.alt}/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <div className="VideoHeader"></div>
        }
    }

}

export default VideoHeader;
