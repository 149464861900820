import React, { Component } from 'react';
import './ProductBanner.css';

class ProductBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productInfo: undefined
        }
    }

    componentWillMount() {
        this.setState({
            productInfo: this.props.productInfo
        });
    }

    render() {
        if ( this.state.productInfo !== undefined ) {
            return (
                <div className="ProductBanner" style = {{ backgroundImage: 'url(' + this.state.productInfo.acf.product_banner_background_image.url + ')'}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 offset-md-1">
                                <h4 className="Product_bannerTittle text-right">{this.state.productInfo.acf.product_banner_title}</h4>
                                {/*<div className="ProductBanner_line" style={{backgroundColor: this.state.productInfo.acf.base_color}}/>*/}
                                <div className="ProductBanner_line"/>
                                <h5 className="Product_bannerDescription">{this.state.productInfo.acf.product_banner_subtitle}</h5>
                            </div>
                            <div className="col-md-2 offset-md-3 ProductBanner_MadeBySevenSoftIconContainer">
                                <img className="ProductBanner_MadeBySevenSoftIcon" src="../res/icons/MadeBySevenSoft.svg" alt="Proudly made by SevenSoft"/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <p className="text-center">Cargando ...</p>
        }
    }

}

export default ProductBanner;
