import React, { Component } from 'react';
import './ServiceSimple.css';

/*-----------------------------------------------------------------------------
    Nombre:selectServiceByID.
    Descripción: Método creado para seleccionar un servicio del array, por su id,
    luego se crea en el state del componente ServiceSimple (componente actual)
    Fecha de creación: 07/12/2018
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function selectServiceByID(component, serviceList, serviceID) {
    for (let i = 0; i < serviceList.length; i++) {
        if(serviceID===serviceList[i].id){
            component.state.serviceSelected = serviceList[i];
        }
    }
}

class ServiceSimple extends Component{
    constructor(props) {
        super(props);
        this.state = { serviceSelected: undefined }
    }

    render (){
        selectServiceByID(this, this.props.service, this.props.serviceID);
        let ServiceSimpleBG = '#000';
        if( this.state.serviceSelected.better_featured_image !== null) {
            ServiceSimpleBG = 'url('+this.state.serviceSelected.better_featured_image.source_url+')';
       }
        return (
            <div id={this.state.serviceSelected.title.rendered.replace(/ /g, "")} className="ServiceSimple col-md-6" style={{background: ServiceSimpleBG }}>
            {/*<div id={this.state.serviceSelected.title.rendered.replace(/ /g, "")} className="ServiceSimple col-md-6" style={{backgroundColor: this.state.serviceSelected.acf.background_color}}>*/}
                <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-sm-12">
                    <h2>{this.state.serviceSelected.acf.title_line_1}</h2>
                    <h1>{this.state.serviceSelected.acf.title_line_2}</h1>
                    <div className="serviceBasicLine" style={{backgroundColor: this.state.serviceSelected.acf.base_color}}/>
                    <pre>{this.state.serviceSelected.acf.description}</pre>
                </div>
            </div>
        );
    };
}

export default ServiceSimple;