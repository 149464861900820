import React, { Component } from 'react';
import swal from 'sweetalert2';
/* Componentes propios */
import TopMenu from './TopMenu/TopMenu';
import Home from './pages/Home';
import Services from './pages/Services';
import Work from './pages/Work';
import Contact from './pages/Contact';
import About from './pages/About';
import Footer from './Footer/Footer';
/* Routing */
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
//Variable constante para establecer el servidor de wordpress
var server = "";
// eslint-disable-next-line
var wpLocation;

class DefaultLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pathName: '/'
        };
        this.openMessagePopup.bind(this);
    }

    componentWillMount(){
        //Variable constante para establecer el servidor de wordpress
        server = this.props.server;
        wpLocation = this.props.wpLocation;


    }

    openMessagePopup () {
       let timer = 15000;
       let contactMessage = swal({
            title: '<span className="copyThis">info@sevensoftit.com</span>',
            imageUrl: '../res/icons/contactMessage.svg',
            customClass: 'contactPopUp rollIn',
            showCancelButton: true,
            confirmButtonColor: '#e92899',
            cancelButtonColor: '#e92899',
            confirmButtonText: 'Send us a message',
            timer: timer,
            cancelButtonText: 'Copy email <span class="space"> .</span> <img src="../res/icons/copy.svg">'
        })
            .then((result) => {
            if (result.value) {
                //Redirecciona a la pagina de contacto
                window.location.href = "/contact";
            }
            if (result.dismiss === 'cancel') {
                // Create new element
                var el = document.createElement('textarea');
                // Set value (string to be copied)
                el.value = 'info@sevensoftit.com';
                // Set non-editable to avoid focus and move outside of view
                el.setAttribute('readonly', '');
                el.style = {position: 'absolute', left: '-9999px'};
                document.body.appendChild(el);
                // Select text inside element
                el.select();
                // Copy text to clipboard
                document.execCommand('copy');
                // Remove temporary element
                document.body.removeChild(el);
                //Se confirma la accion

                let timerInterval;
                swal({
                    title: 'Copied to clipboard!',
                    showConfirmButton: false,
                    type: 'success',
                    timer: 1000,
                    onClose: () => {
                        clearInterval(timerInterval)
                    }
                });
            } else {
                let timerInterval;
                swal({
                    title: 'Feel free to contact us anytime',
                    showConfirmButton: false,
                    timer: 1500,
                    onClose: () => {
                        clearInterval(timerInterval)
                    }
                });
            }
        });


    }

    render() {
        return (
            <div className="DefaultLayout">
                <Router>
                    <div>
                        <TopMenu menusInfo={this.props.menusInfo} menuID="206" server={server}/>
                        <Switch>
                            <Route exact path="/" render={()=><Home siteData={this.props.siteData} server={server} wpLocation={this.props.wpLocation} pageID={31}/>}/>
                            <Route path="/about" render={()=><About siteData={this.props.siteData} server={server} wpLocation={this.props.wpLocation} pageID={31}/>}/>
                            <Route path="/contact" render={()=><Contact siteData={this.props.siteData} server={server} wpLocation={this.props.wpLocation} pageID={28}/>}/>
                            <Route path="/services" render={()=><Services siteData={this.props.siteData} server={server} wpLocation={this.props.wpLocation}/>}/>
                            <Route path="/work/:id" render={(props)=><Work siteData={this.props.siteData} server={server} wpLocation={this.props.wpLocation} {...props} />} />
                            {/*<Redirect to="/" />*/}

                            <Route path="*" render={()=><Home siteData={this.props.siteData} server={server} wpLocation={this.props.wpLocation} pageID={31}/>}/>
                        </Switch>
                        <button className="chatIconButton" type="submit" onClick={() => { this.openMessagePopup() }}>
                            <img className="chatIcon" src="../res/icons/chatIcon.svg" alt="Chat with us"/>
                        </button>
                        <Footer menusInfo={this.props.menusInfo} menuID="204"/>
                    </div>
                </Router>
            </div>
        );
    }

}

export default DefaultLayout;
