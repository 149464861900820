import React, { Component } from 'react';
import LoadingScreen from "react-loading-screen";
import {Redirect} from "react-router-dom";

import ProductHeader from "../ProductHeader/ProductHeader";
import ProductDescription from "../ProductDescription/ProductDescription";
import ProductBanner from "../ProductBanner/ProductBanner";
import ProductPromotion from "../ProductPromotion/ProductPromotion";

/*-----------------------------------------------------------------------------
    Nombre:searchItem.
    Descripción: Método creado para buscar elementos, segun los datos brindados
    Fecha de creación: 12/12/2018
    Autor: Josué Somarribas.
-------------------------------------------------------------------------------*/
function searchItem(component, itemList, searchValue) {
    var ret = false;
    for (let i = 0; i < itemList.length; i++) {
        console.log(searchValue+"=?"+itemList[i].acf.slug)
        if(searchValue===itemList[i].acf.slug){
            ret = itemList[i];
           // return true;

        } else {
           // ret = false;
        }
    }
    return ret;
}
/*-----------------------------------------------------------------------------
     Nombre:loadInfo.
     Descripción: Metodo para obtener los datos de wordpress hasta que se carguen.
     Fecha de creación: 4/12/2018
     Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function loadInfo(component) {
    if(component.props.siteData.name === undefined) {
        document.title = "Making the magic ..." ;
        setTimeout(function(){
            loadInfo(component);
        }, 30);
    } else {
        document.title = component.props.siteData.name + " - Work";
    }
}

class Work extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        }
    }

    componentDidMount() {
        loadInfo(this);
    }

    componentWillMount() {
        fetch(this.props.server+this.props.wpLocation+'wp-json/wp/v2/products/')
            .then((response) => {
                return response.json()
            })
            .then((products) => {
                this.setState({ products: products });
            });
    }

    render() {
        if( this.state.products[0] !== undefined ){
            let item = searchItem(this, this.state.products, this.props.match.params.id);
            if ( item !==false ){
                return (
                    <div className="Work">
                        <ProductHeader item={item}/>
                        <ProductDescription item={item} server={this.props.server} wpLocation={this.props.wpLocation}/>
                        <ProductBanner productInfo={item}/>
                        <br/>
                        <br/>
                        <br/>
                        <hr/>
                        <ProductPromotion productInfo={item}/>
                    </div>
                );
            } else {
                return (
                    <Redirect to="/" />
                );
            }



        } else {
            return (
                <LoadingScreen
                    loading={true}
                    bgColor='#000'
                    spinnerColor='#009FE3'
                    textColor='#fff'
                    logoSrc='../res/icons/SevenSoftIcon.svg'
                    text='Loading'>
                    <p>Content done</p>
                </LoadingScreen>
            );
        }

    }

}

export default Work;
