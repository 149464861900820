import React, { Component } from 'react';
import './ProductOnHome_one.css';

class ProductOnHome_one extends Component {

    render() {
        if (this.props.productsList[0] !== undefined) {
            var item = undefined;
            for (let i = 0; i < this.props.productsList.length; i++) {
                if ( this.props.productsList[i].acf.position === '1' ) {
                    item = this.props.productsList[i];
                }
            }
            if ( item !== undefined ) {
                return (
                    <a className='container-fluid' href={"/work/"+item.acf.slug }>
                        <div className='ProductOnHome_one col-md-12' style={{ backgroundImage: 'url(' + item.acf.product_background_image.url + ')'}}>
                            <div className="row">
                                <div className="col-md-10 offset-md-2">
                                    <h6 className='text-center'>A solution made for</h6>
                                    <br/>
                                    <img className="ProductOnHome_one_clientLogo col-md-12" src={ item.acf.product_logo.url } alt={"Image - "+item.acf.product_descriptive_image.alt}/>
                                </div>
                            </div>
                            <div className="ProductOnHome_one_description row">
                                <h3 className="col-md-12 text-center">{ item.acf.product_subtitle }</h3>
                                <h5 className="col-md-12 text-center">{ item.acf.product_description_paragraph_title }</h5>
                            </div>
                        </div>
                    </a>
                )
            } else {
                return (
                    <span/>
                )
            }

        } else {
            return (
                <span/>
            )
        }
    }
}

export default ProductOnHome_one;
