import React, { Component } from 'react';
import './ServiceBasic.css';
/*-----------------------------------------------------------------------------
    Nombre:selectServiceByID.
    Descripción: Método creado para seleccionar un servicio del array, por su id,
    luego se crea en el state del componente ServiceBasic (componente actual)
    Fecha de creación: 5/12/2018
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function selectServiceByID(component, serviceList, serviceID) {
    for (let i = 0; i < serviceList.length; i++) {
        if(serviceID===serviceList[i].id){
            component.state.serviceSelected = serviceList[i];
        }
    }
}

class ServiceBasic extends Component {
    constructor(props) {
        super(props);
        this.state = { serviceSelected: undefined }
    }

    render() {

        selectServiceByID(this, this.props.service, this.props.serviceID);
       // console.log(this.props.service)
        return (
            <div id={this.state.serviceSelected.title.rendered.replace(/ /g, "")} className="ServiceBasic">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 offset-md-1">
                            <img className="ServiceBasic_img img-fluid" src={this.state.serviceSelected.better_featured_image.source_url} alt={this.state.serviceSelected.better_featured_image.alt_text}/>
                        </div>
                        <div className="col-md-4 offset-md-2 text-right">
                            <h2>{this.state.serviceSelected.acf.title_line_1}</h2>
                            <h1>{this.state.serviceSelected.acf.title_line_2}</h1>
                            <div className="serviceBasicLine" style={{backgroundColor: this.state.serviceSelected.acf.base_color}}/>
                            <p>{this.state.serviceSelected.acf.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ServiceBasic;
