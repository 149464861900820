import React, { Component } from 'react';
import {Link} from "react-router-dom";
import './Footer.css';

/*-----------------------------------------------------------------------------
    Nombre:selectItemByID.
    Descripción: Método creado para seleccionar un item del array, por su id,
    luego se crea en el state del componente actual
    Fecha de creación: 5/12/2018
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function selectItemByID(component, itemList, itemID) {
    for (let i = 0; i < itemList.length; i++) {
        // eslint-disable-next-line
        if (itemList[i].id == itemID) {
    component.state = {menusInfo: itemList[i]};
}
    }
}

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { menusInfo: undefined }
    }


    render() {
        selectItemByID(this, this.props.menusInfo, this.props.menuID);
        if(this.state.menusInfo !== undefined){
            return (
                <div className="Footer">
                    <div className="container">
                        <div className="row">
                            <Link className="footer_logo_container" to="/" alt="Homepage">
                                <img className="footer_icon" src={this.state.menusInfo.acf.logo_icon.url} alt="Homepage"/>
                            </Link>
                            <hr/>
                        </div>
                        <div className="row">
                            <div className="footer_divider col-md-12"/>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-2 offset-md-1">
                                <h5 className="footer_slogan">It's possible</h5>
                            </div>
                            <div className="col-md-1 offset-md-1">
                                <Link className="footer_link" to="/">Home</Link>
                            </div>
                            <div className="col-md-1">
                                <Link className="footer_link" to="/about">About</Link>
                            </div>
                            <div className="col-md-1">
                                <Link className="footer_link" to="/services">Services</Link>
                            </div>
                            <div className="col-md-1">
                                <Link className="footer_link" to="/contact">Contact</Link>
                            </div>
                            <div className="col-md-2 offset-md-1 footer_socialIcons">
                                {/*<a className="footer_icon float-right" href="https://www.pexels.com/" target="_blank"><img src="/res/icons/facebook_dark.svg" alt="Facebook link"/></a>*/}
                                {/*<a className="footer_icon float-right" href="https://www.pexels.com/" target="_blank"><img src="/res/icons/linkedin_dark.svg" alt="LinkedIn link"/></a>*/}
                                {/*<a className="footer_icon float-right" href="https://www.pexels.com/" target="_blank"><img src="/res/icons/google_dark.svg" alt="Google Plus link"/></a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Footer">
                    <ul>
                        <li>
                            <Link to="/">Home </Link>
                        </li>
                        <li>
                            <Link to="/services">Services</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                            <Link to="/work">Work</Link>
                        </li>
                    </ul>
                </div>
            );
        }

    }

}

export default Footer;
