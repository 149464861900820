import React, { Component } from 'react';
import './ProductHeader.css';

class ProductHeader extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="ProductHeader row" style = {{ backgroundImage: 'url(' + this.props.item.acf.product_background_image.sizes.large + ')'}}>
                    <div className="ProductHeader_imgBg col-md-6 ">
                        <div className="col-md-8 offset-md-2">
                            <img className="ProductHeader_productLogo col-md-8" src={this.props.item.acf.product_logo.sizes.large} alt={this.props.item.acf.product_logo.alt} />
                            <div className="ProductHeaderLine"/>
                            <h2>{this.props.item.acf.product_subtitle}</h2>
                        </div>
                    </div>
                    <div className="ProductHeader_img col-md-6">
                        <div className="col-md-10">
                            <img className="ProductHeader_productImage" src={this.props.item.acf.product_descriptive_image.url} alt={this.props.item.acf.product_descriptive_image.alt} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ProductHeader;
