import React, { Component } from 'react';
import './AboutUsContent.css';

class AboutUsContent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            pageInfo: undefined
        };
    }

    componentWillMount() {
        this.setState({
            pageInfo: this.props.pageInfo
        });
    }

    render() {
        return (
            <div className="AboutUsContent container-fluid">
                <div className="row">
                    <h2>{this.state.pageInfo.acf.about_us_content_title_line_1} <span>{this.state.pageInfo.acf.about_us_content_title_line_2}</span></h2>
                </div>
                <div className="row">
                    <div className="col-md-12 AboutUs_card_container">
                        <div className="AboutUs_card row">
                            <div className="AboutUsCard_img_container col-md-8" style={{background: 'url('+this.state.pageInfo.acf.card_image.url+')'}}>
                                {/*<img className="AboutUsCard_img" src={this.state.pageInfo.acf.card_image.url} alt={this.state.pageInfo.acf.card_image.alt}/>*/}
                            </div>
                            <div className="AboutUsCard_description_container col-md-4">
                                <h6>{this.state.pageInfo.acf.about_us_content_subtitle}</h6>
                                <p>{this.state.pageInfo.acf.about_us_content_description}</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}
export default AboutUsContent;