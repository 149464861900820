import React, { Component } from 'react';
import $ from 'jquery';
import './ContactForm.css';
import { CustomInput, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import swal from 'sweetalert2';
import '../../../node_modules/animate.css/animate.css';
import ReactGA from 'react-ga';

var fitty = require('../../../node_modules/fitty/dist/fitty.min');
window.$ = $;

function addNotification(component) {
    let timerInterval
    swal({
        title: 'Thank you. Message sent!',
        html: 'We will contact you soon',
        // footer: '<b>We will contact you soon</b>',
        showConfirmButton: false,
        timer: 4000,
        onBeforeOpen: () => {
            // swal.showLoading()
            // timerInterval = setInterval(() => {
            //     swal.getContent().querySelector('strong')
            //         .textContent = swal.getTimerLeft()
            // }, 100)
        },
        onClose: () => {
            clearInterval(timerInterval)
        }
    }).then((result) => {
        if (
            // Read more about handling dismissals
            result.dismiss === swal.DismissReason.timer
        ) {
            ///console.log('Message sent successfully')
        }
    });

    setTimeout(
        function() {
            component.forceUpdate()
        },
        1200
    );
}


class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
          contactName: '',
          contactEmail: '',
          contactMessage: '',
            contactReason: 'Unknown reason',
            contactReasonQuote: false,
            contactReasonHelp: false,
            contactReasonComment: false,
        };

        this.handleSubmit =  this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeReason = this.handleChangeReason.bind(this);
        //redimencionamos el tamaño del titulo con fitty
        fitty('#ContactFormTitle', {maxSize:70})

    }

    componentDidMount(){
        //redimencionamos el tamaño del titulo con fitty
        fitty('#ContactFormTitle', {maxSize:70})
    }

    // Cambia el state del component con el texto actualizado del input
    handleChange(e) {
        this.setState({
            contactEmail: e.target.value,
        });
    }

    // Cambia el state del component con el texto actualizado del input
    handleChangeMessage(e) {
        this.setState({
            contactMessage: e.target.value
        });
    }
    // Cambia el state del component con el texto actualizado del input
    handleChangeName(e) {
        this.setState({
            contactName: e.target.value
        });
    }
    // Cambia el state del component con el texto actualizado del input
    handleChangeReason(event) {
        //Se obtienen los datos del formulario
        const target = event.target;
       // const value = target.type === 'checkbox' ? target.checked : target.value;

        //se limpia el state del mensaje
        this.setState({ contactReason: ''});

        //se actualiza el state correspondiente
        if(target.id === 'contactReasonQuote') {
            this.setState({ contactReasonQuote: target.checked});
        }
        if(target.id === 'contactReasonComment') {
            this.setState({ contactReasonComment: target.checked});
        }
        if(target.id === 'contactReasonHelp') {
            this.setState({ contactReasonHelp: target.checked});
        }
        //Se crea el mensaje del asunto del correo
        setTimeout(
            function() {
                let numberOfReasons = 0;
                let message = 'Unknown reason';
                if(this.state.contactReasonQuote) {
                    message = 'I need a quote';
                    numberOfReasons += 1 ;
                }
                if(this.state.contactReasonComment) {
                    numberOfReasons += 1 ;
                    if(numberOfReasons > 1) {
                        message=message+', also I have a comment';
                    } else {
                        message='I have a comment';
                    }
                }
                if(this.state.contactReasonHelp) {
                    numberOfReasons += 1 ;
                    if(numberOfReasons > 1) {
                        message=message+' and I need help';
                    } else {
                        message='I have need help';
                    }
                }
                this.setState({ contactReason: message});
            }
                .bind(this),
            500
        );
    }

    handleSubmit(e) {
        //Evitamos que la pagina se recargue
        e.preventDefault();
        // se actualiza el state del componente
        this.setState({
            contactEmail: '',
            contactMessage: '',
            contactName: '',
            contactReason: '',
            contactReasonQuote: false,
            contactReasonHelp: false,
            contactReasonComment: false,
        });
        //Se envia el correo
        $.ajax({
            url: '/res/mail/index.php',
            type: 'GET',
            data: {
                'message': this.state.contactMessage,
                'email': this.state.contactEmail,
                'name': this.state.contactName,
                'reason': this.state.contactReason,
            },
            cache: false,
            success: function(data) {
                // Success..
                // this.setState({
                //     contactEmail: 'success',
                //     contactMessage: '<h1>yay</p>'
                // });
                // $('#formContact').slideUp();
                // $('#formContact').after(this.state.contactMessage);
                // console.log('success', data);
            },
            // Fail..
            error: function(xhr, status, err) {
                // console.log(xhr, status);
                // console.log(err);
                // this.setState({
                //     contactEmail: 'danger',
                //     contactMessage: err+"+"+status+":"+xhr
                // });
                // console.log(this.state.contactEmail + this.state.contactMessage + 'fail');
            }
        });
        //Registramos la accion el google analytics
        ReactGA.event({
            category: 'User',
            action: 'Send a contact email'
        });
        //Se notifica el envio
        addNotification(this);
    }

    render() {

        //redimencionamos el tamaño del titulo con fitty
        fitty('#ContactFormTitle', {maxSize:70})
        return (
            <div className="ContactForm container-fluid">
                <div className="col-md-12">
                    <div className="row">
                        <div className='col-md-4 offset-md-4'>

                            <h1 id='ContactFormTitle'>LET’S GET IN TOUCH</h1>
                            <div className="ContactForm_line"/>
                            <h5>We are glad to hear from you</h5>
                        </div>
                    </div>
                    <Form className="ContactForm_formContainer" onSubmit={this.handleSubmit} id="formContact">
                        <div className="row">
                            <div className="col-md-5 offset-md-1">
                                <FormGroup>
                                    <Label for="formName">Whats your name?</Label>
                                    <Input className="ContactForm_input" placeholder="Tell us your name here, please" id="formName" type="text" name="formName" value={this.state.contactName} onChange={this.handleChangeName} required/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="reasonOfThisMessage">Reason of this message (Please select at least one)</Label>
                                    <div className="ContactForm_reasonContainer">
                                        <CustomInput type="checkbox" id="contactReasonQuote" label="I need a quote"  value={this.state.contactReasonQuote} onChange={this.handleChangeReason} checked={this.state.contactReasonQuote}/>
                                        <hr/>
                                        <CustomInput type="checkbox" id="contactReasonHelp" label="I need help, please" value={this.state.contactReasonHelp} onChange={this.handleChangeReason} checked={this.state.contactReasonHelp}/>
                                        <hr/>
                                        <CustomInput type="checkbox" id="contactReasonComment" label="Comments or suggestions" value={this.state.contactReasonComment} onChange={this.handleChangeReason} checked={this.state.contactReasonComment}/>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-md-5">
                                <FormGroup>
                                    <Label for="formEmail">And your email?</Label>
                                    <Input className="ContactForm_input" type="email" placeholder="We will use this to contact your back, no spam, it’s a prommise" id="formEmail" name="formEmail" value={this.state.contactEmail} onChange={this.handleChange} required/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="formMsg">Finally, your message</Label>
                                    <Input className="ContactForm_input" type="textarea" placeholder="What do you want to tell us?" id="formMsg" name="formMsg" rows="8" cols="40" value={this.state.contactMessage} onChange={this.handleChangeMessage} required/>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <Button className="ContactForm_button col-md-2 offset-md-5">Send message</Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default ContactForm;
