import React, { Component } from 'react';
import './SimpleHeader.css';

class SimpleHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageInfo: undefined
        }
    }

    componentWillMount() {
        this.setState({
            pageInfo: this.props.pageInfo
        });
    }

    render() {
        if ( this.state.pageInfo !== undefined ) {
            return (
                <div className="SimpleHeader" style = {{ backgroundImage: 'url(' + this.state.pageInfo.acf.bg_image.url + ')'}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 offset-md-1">
                                <h2>{this.state.pageInfo.acf.title_line_1}</h2>
                                <h1>{this.state.pageInfo.acf.title_line_2}</h1>
                                <div className="SimpleHeader_line" style={{backgroundColor: this.state.pageInfo.acf.base_color}}/>
                                <h3>{this.state.pageInfo.acf.subtitle}</h3>

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <p className="text-center">Cargando header ...</p>
        }
    }

}

export default SimpleHeader;
