import React, { Component } from 'react';
import './SolutionsSection.css';

//Variable constante para establecer el servidor de wordpress
var server = "";
var wpLocation = "";

class SolutionsSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            pageInfo: []
        }
    }


    componentWillMount(){
        //Variable constante para establecer el servidor de wordpress
        server = this.props.server;
        wpLocation = this.props.wpLocation;

        fetch(server+wpLocation+'wp-json/wp/v2/services/')
            .then((response) => {
                return response.json()
            })
            .then((services) => {
                this.setState({ services: services });
            });
        fetch(this.props.server+this.props.wpLocation+"wp-json/wp/v2/pages/"+this.props.pageID)
            .then((response) => {
                return response.json()
            })
            .then((pageInfo) => {
                this.setState({ pageInfo: pageInfo });
            });
    }


    render() {
        //Si no hay mas de dos servicios, esta seccion se oculta
        if(this.state.services.length > 2 && this.state.pageInfo.acf !== undefined) {
            console.log('dsf')
            console.log(this.state.pageInfo)
            var cards = this.state.services.map(function(item, i){
                if(i < 10) {
                    return <a href={'/services'} key={i} className="SolutionsSection_card">
                                <img src={item.acf.card_icon.url} alt={item.acf.card_icon.alt}/>
                                <h4>{item.title.rendered}</h4>
                            </a>;
                } else {
                    return <span/>;
                }
            });


            return (
                <div className="SolutionsSection container-fluid">
                    <div className="row">
                        <h3>We are a group of dedicated people who love making cutting-edge solutions</h3>
                    </div>
                    <div className="SolutionsSection_card_container col-md-8 offset-md-2 row">
                        {cards}
                    </div>
                    <div className="row">
                        <br/>
                        <br/>
                        <h3>And much more</h3>
                        <a href={'/services'} className="SolutionsSection_button col-md-2 offset-md-5">Come and see</a>
                    </div>
                    <div className="SolutionsSection_Banner row" style={{background: 'url('+this.state.pageInfo.acf.mini_banner_background_image.url+')'}}>
                        <div className="col-md-2 offset-md-9">
                            <h4>{this.state.pageInfo.acf.mini_banner_text_line_1}</h4>
                            <h1>{this.state.pageInfo.acf.mini_banner_text_line_2}</h1>
                        </div>
                        <div className="col-md-1">
                            <img src={this.state.pageInfo.acf.mini_banner_image.url} alt={this.state.pageInfo.acf.mini_banner_image.alt} />
                        </div>
                        <br/>
                    </div>
                </div>
            );
        } else {
            return(
                <div className='container-fluid'>
                    <div className="row">
                        <div className="SolutionsSection_loading col-md-12">
                            <br/>
                        </div>
                    </div>
                </div>
            );
        }



    }

}

export default SolutionsSection;
