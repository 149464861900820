import React, { Component } from 'react';
import './SimpleBanner.css';

class SimpleBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageInfo: undefined
        }
    }

    componentWillMount() {
        this.setState({
            pageInfo: this.props.pageInfo
        });
    }

    render() {
        if ( this.state.pageInfo !== undefined ) {
            return (
                <div className="SimpleBanner banner-bg" style = {{ backgroundImage: 'url(' + this.state.pageInfo.acf.about_us_banner_background_image.url + ')'}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 offset-md-1">
                                <h4 className="bannerTittle text-right">{this.state.pageInfo.acf.about_us_banner_title_line_1}</h4>
                                <h4 className="bannerSubtittle text-right"> {this.state.pageInfo.acf.about_us_banner_title_line_2}</h4>
                                <div className="SimpleBanner_line" style={{backgroundColor: this.state.pageInfo.acf.base_color}}/>
                                <h5 className="bannerDescription">{this.state.pageInfo.acf.about_us_banner_description}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <p className="text-center">Cargando ...</p>
        }
    }

}

export default SimpleBanner;
