import React, { Component } from 'react';
import SimpleHeader from "../SimpleHeader/SimpleHeader";
import AboutUsContent from "../AboutUsContent/AboutUsContent";
import SimpleBanner from "../SimpleBanner/SimpleBanner";
import LoadingScreen from "react-loading-screen";
/*-----------------------------------------------------------------------------
     Nombre:loadInfo.
     Descripción: Metodo para obtener los datos de wordpress hasta que se carguen.
     Fecha de creación: 4/12/2018
     Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function loadInfo(component) {
    if(component.props.siteData.name === undefined) {
        document.title = "Making the magic ..." ;
        setTimeout(function(){
            loadInfo(component);
        }, 30);
    } else {
        document.title = component.props.siteData.name + " - About";
    }
}

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageInfo: undefined
        }
    }

    componentWillMount(){
        //Consultamos los datos de wordpress
        let url = this.props.server+this.props.wpLocation+"wp-json/wp/v2/pages/"+this.props.pageID;
        fetch(url)
            .then((response) => {
                return response.json()
            })
            .then((pageInfo) => {
                this.setState({ pageInfo: pageInfo });
            });
    }

    componentDidMount() {
        loadInfo(this);
    }

    render() {
        if ( this.state.pageInfo !== undefined ){
            return (
                <div className="About">
                    <SimpleHeader pageInfo={this.state.pageInfo}/>
                    <AboutUsContent pageInfo={this.state.pageInfo}/>
                    <SimpleBanner pageInfo={this.state.pageInfo}/>
                </div>
            );
        } else  {
            return (
                <LoadingScreen
                    loading={true}
                    bgColor='#000'
                    spinnerColor='#009FE3'
                    textColor='#fff'
                    logoSrc='res/icons/SevenSoftIcon.svg'
                    text='Loading'>
                    <p>Loadable content</p>
                </LoadingScreen>
            );
        }
    }

}

export default About;
