import React, { Component } from 'react';
//Estilos
import './App.css';
import './animate.css';
//Componetes propios
import DefaultLayout from './components/DefaultLayout';
//Componente para pantalla de carga, ayuda en las auditorias del sitio
import LoadingScreen from 'react-loading-screen';
//Componente para manejar las meta tags del sitio
import MetaTags from 'react-meta-tags';
//Analytics
import ReactGA from 'react-ga';
//Variable constante para establecer el servidor de wordpress
//const server = 'http://192.168.100.32:8080/';
//const wpLocation = 'sevensoft_site/admin/';
const server = 'https://admin.sevensoftit.com/';
const wpLocation = 'sevensoftword/index.php/';

class App extends Component {
  /*-----------------------------------------------------------------------------
    Nombre:Constructor.
    Descripción: Constructor en el cuál se crean las variables para acceder a los
                 datos de wordpress mediante API REST
    Fecha de creación: 29/11/2018
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
    constructor (){
        super();
        this.state = {
            siteData: [],
            menusInfo: [],
            contentLoading: true
        }

        ReactGA.initialize('UA-129524116-1');
        ReactGA.pageview('/homepage');

    }
  /*-----------------------------------------------------------------------------
     Nombre:componentDidMount.
     Descripción: Metodo para obtener los datos de wordpress.
     Fecha de creación: 29/11/2018
     Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
    componentDidMount() {
        let siteDataUrl = server+wpLocation+"wp-json/";
        fetch(siteDataUrl)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    siteData: response
                })
            });
        let menusInfo = server+wpLocation+"wp-json/wp/v2/menu";
        fetch(menusInfo)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    menusInfo: response,
                    contentLoading: false
                })
            });
    }
  render() {
    return (
      <div className="App">
          <LoadingScreen
              loading={this.state.contentLoading}
              bgColor='#000'
              spinnerColor='#009FE3'
              textColor='#fff'
              logoSrc='../res/icons/SevenSoftIcon.svg'
              text='Loading'>
              <MetaTags>
                  <meta name="description" content={ this.state.siteData.description }/>
              </MetaTags>
              <DefaultLayout siteData={this.state.siteData} server={server} wpLocation={wpLocation} menusInfo={this.state.menusInfo}/>
          </LoadingScreen>
      </div>
    );
  }
}

export default App;
