import React, { Component } from 'react';
import './Quote.css';

/*-----------------------------------------------------------------------------
    Nombre:selectItemByID.
    Descripción: Método creado para seleccionar un item del array, por su id,
    luego se crea en el state del componente actual
    Fecha de creación: 5/12/2018
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
// function selectItemByID(component, itemList, itemID) {
//      for (let i = 0; i < itemList.length; i++) {
//          if(itemID===itemList[i].id){
//              component.state.quoteSelected = itemList[i];
//          }
//      }
// }
/*-----------------------------------------------------------------------------
    Nombre:selectItemByIndex.
    Descripción: Método creado para seleccionar un item del array, por su posicion en el array,
                si no existe, elige el anterior
    Fecha de creación: 5/12/2018
    Autor: Josué Somarribas.
     -------------------------------------------------------------------------------*/
function selectItemByIndex(component, itemList, itemIndex) {
    let cantidadTestimonios = itemList.length;
    let positionTestimonio = itemIndex;

    if (positionTestimonio < 0){
        positionTestimonio = 0;
    }
    if (positionTestimonio > cantidadTestimonios) {
        positionTestimonio = cantidadTestimonios;
    }

     component.state.quoteSelectedIndex = positionTestimonio-1;
     // console.log(positionTestimonio)
     // console.log(component.props.quote[positionTestimonio])
}

class Quote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quoteSelectedIndex: undefined,
        }
    }

    componentDidMount() {
       // selectItemByID(this, this.props.quote, this.props.quoteID);
        selectItemByIndex(this, this.props.quote, this.props.quoteIndex);
    }

    render() {
     //   selectItemByID(this, this.props.quote, this.props.quoteID);
        selectItemByIndex(this, this.props.quote, this.props.quoteIndex);


        let testimonio = this.props.quote[this.state.quoteSelectedIndex];

        let fbLink ="", linkedInLink="", GPlusLink="";
        if ( testimonio.acf.facebook_url !== ''){
            fbLink = <a className="quoteIcon float-right" href={testimonio.acf.facebook_url } rel="noopener noreferrer" target="_blank"><img src="/res/icons/facebook.svg" alt="Facebook icon"/></a>;
        }
        if ( testimonio.acf.google_plus_url !== ''){
            GPlusLink = <a className="quoteIcon float-right" href={testimonio.acf.google_plus_url} rel="noopener noreferrer" target="_blank"><img src="/res/icons/google.svg" alt="Google icon"/></a>;
        }
        if ( testimonio.acf.linked_in_url !== ''){
            linkedInLink = <a className="quoteIcon float-right" href={testimonio.acf.linked_in_url} rel="noopener noreferrer" target="_blank"><img src="/res/icons/linkedin.svg" alt="LinkedIn icon"/></a>;
        }
        return (

            <div className="Quote quote-bg" style = {{ backgroundImage: 'url(' + testimonio.acf.testimonial_background.url + ')'}}>
                {console.log(testimonio)}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 offset-md-1">
                            <div className="cropper ">
                                <img src={testimonio.acf.client_selfie.url}  alt={testimonio.acf.client_selfie.alt}/>
                            </div>
                            <h4 className="quoteTittle">{testimonio.acf.author} </h4>
                            <h4 className="quoteTittle"> {testimonio.acf.job} of <span>{testimonio.acf.company_name}</span></h4>
                        </div>
                        <div className="col-md-3 offset-md-4">
                            <h5 className="quoteMessage">"{testimonio.acf.quote}"</h5>
                            <div className="row">
                                {fbLink}
                                {linkedInLink}
                                {GPlusLink}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );




    }

}

export default Quote;
