import React, { Component } from 'react';
/* Componentes propios */
import VideoHeader from '../VideoHeader/VideoHeader';
import ServiceBasic from '../ServiceBasic/ServiceBasic';
import ServiceTwo from '../ServiceTwo/ServiceTwo';
import Quote from '../Quote/Quote';
import ServiceSimple from "../ServiceSimple/ServiceSimple";
import LoadingScreen from "react-loading-screen";

//Variable constante para establecer el servidor de wordpress
var server = "";
var wpLocation = "";

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            quotes: [],
            contentLoaded: true
        }
    }
    componentWillMount(){
        //Variable constante para establecer el servidor de wordpress
        server = this.props.server;
        wpLocation = this.props.wpLocation;

        fetch(server+wpLocation+'wp-json/wp/v2/services/')
            .then((response) => {
                return response.json()
            })
            .then((services) => {
                this.setState({ services: services });
            });
        fetch(server+wpLocation+'wp-json/wp/v2/testimonials/')
            .then((response) => {
                return response.json()
            })
            .then((quotes) => {
                this.setState({ quotes: quotes });
            });
    }

    componentDidMount() {
        this.setState({
            contentLoaded: false
        })
    }

    render() {
        if (this.state.services.length > 0 && this.state.quotes.length > 0) {
            return (
                <LoadingScreen
                    loading={this.state.contentLoaded}
                    bgColor='#000'
                    spinnerColor='#009FE3'
                    textColor='#fff'
                    logoSrc='res/icons/SevenSoftIcon.svg'
                    text='Loading'>
                    <div className="Services">
                        <VideoHeader pageID={25} server={this.props.server} wpLocation={this.props.wpLocation}/>
                        <ServiceBasic service={this.state.services} serviceID={122}/>
                        <ServiceTwo service={this.state.services} serviceID={192}/>
                        <Quote className="quote-bg" quote={this.state.quotes} quoteIndex={1}/>
                        <ServiceBasic service={this.state.services} serviceID={132}/>
                        <Quote quote={this.state.quotes} quoteIndex={2}/>
                        <ServiceBasic service={this.state.services} serviceID={137}/>
                        <ServiceTwo service={this.state.services} serviceID={188}/>
                        <div className="container-fluid">
                            <div className="row">
                                <ServiceSimple service={this.state.services} serviceID={190}/>
                                <ServiceSimple service={this.state.services} serviceID={191}/>
                            </div>
                        </div>
                    </div>
                </LoadingScreen>

            )
        } else {
            return (
                <LoadingScreen
                    loading={true}
                    bgColor='#000'
                    spinnerColor='#009FE3'
                    textColor='#fff'
                    logoSrc='res/icons/SevenSoftIcon.svg'
                    text='Loading'>
                    <p>Loadable content</p>
                </LoadingScreen>
            );
        }
    }

}

export default Services;
